<template>
  <div class="content">
    <el-form ref="iconForm" label-width="110px" v-loading="loading" class="small-form" size="medium"
      :model="form" :rules="rules">
      <el-form-item label="页面顶部名称：" prop="top_name">
        <el-input v-focus v-model="form.top_name" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="上下滑动图标：" prop="slide_icon_on">
        <div class="img-list">
          <div>
            <SingleMediaWall ratio="43:29" v-model="form.slide_icon_on" fit="contain" :width="104" :height="104"
              :borderRadius="4"></SingleMediaWall>
            <el-input v-model="form.slide_icon_on" v-show="false" />
          </div>
          <div style="margin-left: 20px">
            <SingleMediaWall ratio="43:29" v-model="form.slide_icon_below" fit="contain" :width="104" :height="104"
              :borderRadius="4"></SingleMediaWall>
            <el-input v-model="form.slide_icon_below" v-show="false" />
          </div>
        </div>
        <div class="img-tips">建议上传尺寸比例为129*87</div>
      </el-form-item>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="handleSaveConfig('iconForm')" :loading="saveLoading">保存</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import { getCycDecorate, setCycDecorate } from "../api/applets-config";
import SingleMediaWall from "../../common/components/SingleMediaWall";
import FixedActionBar from "../../../base/layout/FixedActionBar";
import regs from "../../../base/utils/regs";

export default {
  name: "applets-renovation",
  data() {
    return {
      loading: false,
      saveLoading: false,
      form: {
        top_name: "",
        slide_icon_on: "",
        slide_icon_below: "",
      },
      select_index: 0,
      rules: {
        top_name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        slide_icon_on: [
          {
            validator: regs.checkImg(
              this,
              ["slide_icon_on", "slide_icon_below"],
              {
                msg: "请选择图标",
              }
            ),
            trigger: "change",
          },
          { required: true, message: "请选择图标" },
        ],
      },
    };
  },
  components: { FixedActionBar, SingleMediaWall },
  created() {
    this.loading = true;
    getCycDecorate()
      .then((res) => {
        this.form = res.data;
      })
      .catch((err) => {})
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleClick(i) {
      this.select_index = i;
      this.showImage = true;
    },
    handleSaveConfig(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          setCycDecorate({ config: this.form })
            .then((res) => {
              this.$message({
                message: "保存成功",
                type: "success",
              });
            })
            .catch(() => {})
            .finally(() => {
              this.saveLoading = false;
            });
        } else {
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  .img-list {
    display: flex;
    align-items: center;
    height: 104px;

    .img-box {
      width: 104px;
      height: 104px;
      border-radius: 4px;

      .upload-btn {
        width: 104px;
        height: 104px;
        border: 1px dashed #d9d9d9;

        .plus-icon {
          font-size: 20px;

          .upload-text {
            font-size: 14px;
            line-height: 30px;
          }
        }

        + p.img-tips {
          margin-top: 10px;
        }
      }
    }
  }

  .img-tips {
    font-size: 12px;
    color: #c0c4cc;
    line-height: 12px;
    margin-top: 10px;
  }
}
</style>
