import api from "@/base/utils/request";

//获取小程序设置
export const getCycDecorate = data => {
    return api({
        url: "/admin/cyc/Settings/getCycDecorateConfig",
        method: "post",
        data
    });
};
//小程序设置
export const setCycDecorate = data => {
    return api({
        url: "/admin/cyc/Settings/setCycDecorateConfig",
        method: "post",
        data
    });
};